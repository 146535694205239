<template>
  <div class="projects-paginated" v-if="projects">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />

    <DataTable
      class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
      ref="projectsPaginatedTable"
      :value="projects"
      dataKey="number"
      :lazy="true"
      :loading="isLoading"
      :totalRecords="getProjectCount"
      :paginator="true"
      :rows.sync="tableState.pagination.rowsPerPage"
      :first="pageOffset"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :currentPageReportTemplate="
        $t('show') +
          ' {first} ' +
          $t('to') +
          ' {last} ' +
          $t('of') +
          ' {totalRecords} ' +
          $t('records')
      "
      :rowsPerPageOptions="[10, 15, 20, 25, 30, 40, 50, 100, 200]"
      :filters.sync="tableState.filters"
      stateStorage="local"
      :stateKey="tableState.filterName"
      filterDisplay="row"
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
      @state-restore="onStateRestore($event)"
      :rowHover="true"
      :sortField="tableState.sortField"
      :sortOrder="tableState.sortOrder"
      responsiveLayout="scroll"
    >
      <template #header>
        <div class="table-header">
          <div class="table-header-left">
            <h4 class="page-title">{{ $t('projects') }}</h4>
          </div>
          <div class="table-header-right">
            <!-- <div>
              <MultiSelect
                :value="selectedColumns"
                :options="columns"
                @input="onToggle"
                optionLabel="header"
                :placeholder="$t('select_columns')"
                style="width: 20em"
                display="chip"
              />
            </div> -->

            <div>
              <Dropdown
                v-model="tableState.customFilters.electricMeterChanged"
                :options="optionsYesNo"
                optionLabel="label"
                :placeholder="$t('electricMeterChanged')"
                :showClear="true"
                @input="onElectricMeterChangedFilter"
                style="width: 12.5em"
              />
            </div>

            <!-- <div>
              <MultiSelect
                :value="tableState.customFilters.projectStates"
                :options="projectStates"
                @input="onProjectStateFilter"
                optionLabel="label"
                :placeholder="$t('projectState')"
                style="min-width: 18em"
                display="chip"
              />
            </div> -->

            <div v-if="isAdmin || isClient">
              <MultiSelect
                :value="tableState.customFilters.clients"
                :options="clients"
                @input="onClientFilter"
                optionLabel="label"
                :placeholder="$t('client')"
                style="min-width: 18em"
                :showClear="true"
              />
            </div>

            <div>
              <MultiSelect
                :value="tableState.customFilters.employers"
                :options="employers"
                @input="onEmployerFilter"
                optionLabel="label"
                :placeholder="$t('employer')"
                style="min-width: 18em"
                display="chip"
                :disabled="employerDropdownDisabled"
              />
            </div>

            <div v-if="isAdmin">
              Archiv-Modus:
              <InputSwitch style="top: 6px;" v-model="archiveMode" @change="onChangeArchiveMode" />
            </div>

            <button class="btn btn-sm btn-inverse" @click.prevent="onRemoveFilters()">
              <i class="fa fa-remove mr-2"></i>
              {{ $t('remove_filters') }}
            </button>

            <ButtonWait
              v-if="isAdmin || isClient"
              :startCallback="startExportBuild"
              :pollCallback="fetchExportBuildState"
              class="pull-right"
            >
              <template v-slot:buttonText>
                {{ $t('projects_export') }}
              </template>
            </ButtonWait>

            <ButtonWait
              v-if="isAdmin"
              :startCallback="startOfferExportBuild"
              :pollCallback="fetchOfferExportBuildState"
              class="pull-right"
            >
              <template v-slot:buttonText>
                {{ $t('projects_offer_export') }}
              </template>
            </ButtonWait>

            <router-link class="btn btn-sm btn-inverse" :to="{ name: 'ProjectCreatePage' }">
              <i class="fa fa-plus-circle mr-2"></i>
              {{ $t('project_create') }}
            </router-link>
          </div>
        </div>
      </template>

      <template #empty>
        {{ $t('no_data_found') }}
      </template>
      <template #loading>
        {{ $t('loading') }}
      </template>

      <Column
        v-if="isAdmin"
        field="number"
        header="#"
        sortable
        :showFilterMenu="false"
        :headerStyle="{ width: '60px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <router-link
            v-if="slotProps.data.number"
            :to="{
              name: 'ProjectEditPage',
              params: { projectNumber: slotProps.data.number },
            }"
            >{{ slotProps.data.number.toString().padStart(4, '0') }}</router-link
          >
        </template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        v-if="isClient"
        field="clientProjectNumber"
        header="#"
        sortable
        :showFilterMenu="false"
        :headerStyle="{ width: '60px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <router-link
            v-if="slotProps.data.number && slotProps.data.clientProjectNumber"
            :to="{
              name: 'ProjectEditPage',
              params: { projectNumber: slotProps.data.number },
            }"
            >{{ slotProps.data.clientProjectNumber.toString().padStart(4, '0') }}</router-link
          >
        </template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        field="employerProjectNumber"
        :header="$t('employerProjectNumber_short')"
        sortable
        :showFilterMenu="false"
        :headerStyle="{ 'min-width': '110px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <div v-if="slotProps.data.employerProjectNumber">
            {{ slotProps.data.employerProjectNumber.toString().padStart(4, '0') }}
          </div>
        </template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        v-if="isAdmin"
        field="projectTypeState"
        :header="$t('projectTypeState')"
        sortable
        :headerStyle="{ 'max-width': '150px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <div v-if="slotProps.data.projectTypeState">
            <b-badge :variant="projectTypeStateColor(slotProps.data.projectTypeState)">
              {{ $t(slotProps.data.projectTypeState) }}
            </b-badge>
          </div>
          <div v-else>N/A</div>
        </template>
        <template #filter>
          <MultiSelect
            :value="tableState.customFilters.projectTypeStates"
            :options="projectTypeStates"
            @input="onProjectTypeStateFilter"
            optionLabel="label"
            :placeholder="$t('projectTypeState')"
            style="max-width: 150px"
            display="chip"
          />
        </template>
      </Column>

      <Column
        v-if="isAdmin"
        field="deliveryAt"
        :header="$t('deliveryAt_short')"
        sortable
        :headerStyle="{ 'max-width': '100px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <span v-if="slotProps.data.deliveryAt">
            {{ $date(slotProps.data.deliveryAt) }}
          </span>
        </template>
        <template #filter="{filterModel,filterCallback}">
          <Calendar
            v-model="filterModel.value"
            @input="filterCallback()"
            selectionMode="single"
            dateFormat="dd.mm.yy"
            placeholder=">="
          >
          </Calendar>
        </template>
      </Column>

      <Column
        v-if="isClient"
        field="createdAt"
        :header="$t('createdAt')"
        sortable
        :headerStyle="{ 'max-width': '100px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <span v-if="slotProps.data.createdAt">
            {{ $date(slotProps.data.createdAt) }}
          </span>
        </template>

        <template #filter="{filterModel,filterCallback}">
          <Calendar
            v-model="filterModel.value"
            @input="filterCallback()"
            selectionMode="single"
            dateFormat="dd.mm.yy"
            placeholder=">="
          >
          </Calendar>
        </template>
      </Column>

      <Column
        v-if="isColumnSelected('projectState')"
        field="projectState"
        :header="$t('state')"
        sortable
        :headerStyle="{ 'max-width': '150px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <b-badge :variant="getProjectStateColor(slotProps.data.projectState)">
            {{ $t(slotProps.data.projectState) }}
          </b-badge>
        </template>
        <template #filter>
          <MultiSelect
            :value="tableState.customFilters.projectStates"
            :options="projectStates"
            @input="onProjectStateFilter"
            optionLabel="label"
            :placeholder="$t('projectState')"
            style="max-width: 150px"
            display="chip"
          />
        </template>
      </Column>

      <Column
        field="deferredState"
        :header="$t('deferred')"
        sortable
        :headerStyle="{ 'max-width': '150px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <div v-if="slotProps.data.deferredState">
            <b-badge :variant="yesNoStateReversedColor(slotProps.data.deferredState.deferred)">
              {{ $t(slotProps.data.deferredState.deferred) }}
            </b-badge>
          </div>
          <div v-else>N/A</div>
        </template>
        <template #filter="{filterModel, filterCallback}">
          <Dropdown
            v-model="filterModel.value"
            :options="yesNo()"
            class="p-column-filter"
            :showClear="true"
            @input="filterCallback()"
          >
            <template #option="slotProps">
              <b-badge :variant="yesNoStateReversedColor(slotProps.option)">
                {{ $t(slotProps.option) }}
              </b-badge>
            </template>
          </Dropdown>
        </template>
      </Column>

      <Column
        field="customer.lastname"
        :header="$t('lastname')"
        filterField="customerLastname"
        sortable
        :showFilterMenu="false"
      >
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        field="customer.firstname"
        :header="$t('firstname')"
        filterField="customerFirstname"
        sortable
        :showFilterMenu="false"
      >
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        field="customer.zip"
        :header="$t('zip')"
        filterField="customerZip"
        sortable
        sortField="customer.zip"
        :showFilterMenu="false"
        :headerStyle="{ width: '60px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        field="customer.city"
        :header="$t('city')"
        filterField="customerCity"
        sortable
        :showFilterMenu="false"
      >
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
        <template #body="slotProps">
          <a :href="generateGoogleMapsLink(slotProps.data.customer)" target="_blank">
            {{ slotProps.data.customer.city }}
          </a>
        </template>
      </Column>

      <Column
        v-if="isAdmin"
        field="customer.phone"
        :header="$t('phone')"
        filterField="customerPhone"
        sortable
        :showFilterMenu="false"
      >
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        v-if="isAdmin"
        field="customer.mobile"
        :header="$t('mobile')"
        filterField="customerMobile"
        sortable
        :showFilterMenu="false"
      >
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        field="powerCompany.company"
        filterField="powerCompany"
        :header="$t('powerCompany')"
        sortable
      >
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        field="askEmployerCategoryState"
        :header="$t('askEmployerCategoryState')"
        sortable
        :headerStyle="{ 'max-width': '150px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <div v-if="slotProps.data.askEmployerCategoryState">
            <b-badge>
              {{ $t(slotProps.data.askEmployerCategoryState) }}
            </b-badge>
          </div>
          <div v-else>N/A</div>
        </template>
        <template #filter>
          <MultiSelect
            :value="tableState.customFilters.askEmployerCategoryStates"
            :options="askEmployerCategoryStates"
            @input="onAskEmployerCategoryStateFilter"
            optionLabel="label"
            :placeholder="$t('askEmployerCategoryState')"
            style="max-width: 150px"
            display="chip"
          />
        </template>
      </Column>

      <Column
        v-if="isAdmin"
        field="solarPlant.solarPlantOverallPower"
        :header="$t('solarPlantOverallPower_short')"
        sortable
        filterField="solarPlantOverallPower"
        :showFilterMenu="false"
        :headerStyle="{ 'max-width': '60px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          {{ $n(slotProps.data.solarPlant.solarPlantOverallPower, 'decimal_triple') }}
        </template>

        <template #filter="{filterModel,filterCallback}">
          <InputText
            type="text"
            v-model="filterModel.value"
            @input="filterCallback()"
            placeholder=">="
          />
        </template>
      </Column>

      <Column
        v-if="isClient"
        field="solarPlant.solarPanelCount"
        :header="$t('solarPanelCount_short')"
        sortable
        filterField="solarPanelCount"
        :showFilterMenu="false"
        :headerStyle="{ 'max-width': '90px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          {{ slotProps.data.solarPlant.solarPanelCount }}
        </template>

        <template #filter="{filterModel,filterCallback}">
          <InputText
            type="text"
            v-model="filterModel.value"
            @input="filterCallback()"
            placeholder=">="
          />
        </template>
      </Column>

      <Column
        v-if="isAdmin"
        field="installation.originalInstallationType"
        :header="$t('originalInstallationType_short')"
        sortable
        :headerStyle="{ 'max-width': '60px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <span
            v-if="
              slotProps.data.installation && slotProps.data.installation.originalInstallationType
            "
          >
            {{ $t(slotProps.data.installation.originalInstallationType + '_short') }}
          </span>
        </template>

        <template #filter>
          <MultiSelect
            :value="tableState.customFilters.originalInstallationTypes"
            :options="originalInstallationTypes"
            @input="onOriginalInstallationTypeFilter"
            optionLabel="label"
            :placeholder="$t('originalInstallationType_short')"
            :style="{ 'max-width': '65px' }"
          />
        </template>
      </Column>

      <Column
        v-if="isAdmin"
        field="initialInformationSent"
        :header="$t('initialInformationSent')"
        sortable
        sortField="initialInformationSentToCustomerAt"
        :headerStyle="{ 'max-width': '80px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <b-badge :variant="getYesNoStateColor(slotProps.data.initialInformationSent)">
            {{ $t(slotProps.data.initialInformationSent) }}
          </b-badge>
        </template>

        <template #filter="{filterModel, filterCallback}">
          <Dropdown
            v-model="filterModel.value"
            :options="yesNo()"
            class="p-column-filter"
            :showClear="true"
            @input="filterCallback()"
          >
            <template #option="slotProps">
              <b-badge :variant="getYesNoStateColor(slotProps.option)">
                {{ $t(slotProps.option) }}
              </b-badge>
            </template>
          </Dropdown>
        </template>
      </Column>

      <Column
        field="consumablesInStockState"
        :header="$t('consumablesInStockState')"
        sortable
        :headerStyle="{ 'max-width': '100px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <b-badge :variant="getYesNoStateColor(slotProps.data.consumablesInStockState)">
            {{ $t(slotProps.data.consumablesInStockState) }}
          </b-badge>
        </template>

        <template #filter="{filterModel, filterCallback}">
          <Dropdown
            v-model="filterModel.value"
            :options="yesNo()"
            class="p-column-filter"
            :showClear="true"
            @input="filterCallback()"
          >
            <template #option="slotProps">
              <b-badge :variant="getYesNoStateColor(slotProps.option)">
                {{ $t(slotProps.option) }}
              </b-badge>
            </template>
          </Dropdown>
        </template>
      </Column>

      <Column
        field="consumablesDeliveredAt"
        :header="$t('consumablesDeliveredAt')"
        sortable
        :headerStyle="{ 'max-width': '100px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <span v-if="slotProps.data.consumablesDeliveredAt">
            {{ $date(slotProps.data.consumablesDeliveredAt) }}
          </span>
        </template>

        <template #filter="{filterModel,filterCallback}">
          <Calendar
            v-model="filterModel.value"
            @input="filterCallback()"
            selectionMode="single"
            dateFormat="dd.mm.yy"
            placeholder=">="
          >
          </Calendar>
        </template>
      </Column>

      <Column
        v-if="false"
        field="netRegistrationRequirementsFullfilledState"
        :header="$t('netRegistrationRequirementsFullfilledState')"
        sortable
        :headerStyle="{ 'max-width': '80px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <b-badge
            :variant="getYesNoStateColor(slotProps.data.netRegistrationRequirementsFullfilledState)"
          >
            {{ $t(slotProps.data.netRegistrationRequirementsFullfilledState) }}
          </b-badge>
        </template>

        <template #filter="{filterModel, filterCallback}">
          <Dropdown
            v-model="filterModel.value"
            :options="yesNo()"
            class="p-column-filter"
            :showClear="true"
            @input="filterCallback()"
          >
            <template #option="slotProps">
              <b-badge :variant="getYesNoStateColor(slotProps.option)">
                {{ $t(slotProps.option) }}
              </b-badge>
            </template>
          </Dropdown>
        </template>
      </Column>

      <Column
        v-if="false"
        field="operationRegistrationRequirementsFullfilledState"
        :header="$t('operationRegistrationRequirementsFullfilledState')"
        sortable
        :headerStyle="{ 'max-width': '80px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <b-badge
            :variant="
              getYesNoStateColor(slotProps.data.operationRegistrationRequirementsFullfilledState)
            "
          >
            {{ $t(slotProps.data.operationRegistrationRequirementsFullfilledState) }}
          </b-badge>
        </template>

        <template #filter="{filterModel, filterCallback}">
          <Dropdown
            v-model="filterModel.value"
            :options="yesNo()"
            class="p-column-filter"
            :showClear="true"
            @input="filterCallback()"
          >
            <template #option="slotProps">
              <b-badge :variant="getYesNoStateColor(slotProps.option)">
                {{ $t(slotProps.option) }}
              </b-badge>
            </template>
          </Dropdown>
        </template>
      </Column>

      <Column
        v-if="false"
        field="powerCompany.netRegistrationDocumentsApprovedState"
        filterField="netRegistrationDocumentsApprovedState"
        :header="$t('netRegistrationDocumentsApprovedState_short')"
        sortable
        :headerStyle="{ 'max-width': '80px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <b-badge
            v-if="slotProps.data.powerCompany"
            :variant="
              getYesNoStateColor(slotProps.data.powerCompany.netRegistrationDocumentsApprovedState)
            "
          >
            {{ $t(slotProps.data.powerCompany.netRegistrationDocumentsApprovedState) }}
          </b-badge>
        </template>

        <template #filter="{filterModel, filterCallback}">
          <Dropdown
            v-model="filterModel.value"
            :options="yesNo()"
            class="p-column-filter"
            :showClear="true"
            @input="filterCallback()"
          >
            <template #option="slotProps">
              <b-badge :variant="getYesNoStateColor(slotProps.option)">
                {{ $t(slotProps.option) }}
              </b-badge>
            </template>
          </Dropdown>
        </template>
      </Column>

      <Column
        v-if="false"
        field="powerCompany.operationRegistrationDocumentsApprovedState"
        filterField="operationRegistrationDocumentsApprovedState"
        :header="$t('operationRegistrationDocumentsApprovedState_short')"
        sortable
        :headerStyle="{ 'max-width': '80px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <b-badge
            v-if="slotProps.data.powerCompany"
            :variant="
              getYesNoStateColor(
                slotProps.data.powerCompany.operationRegistrationDocumentsApprovedState
              )
            "
          >
            {{ $t(slotProps.data.powerCompany.operationRegistrationDocumentsApprovedState) }}
          </b-badge>
        </template>

        <template #filter="{filterModel, filterCallback}">
          <Dropdown
            v-model="filterModel.value"
            :options="yesNo()"
            class="p-column-filter"
            :showClear="true"
            @input="filterCallback()"
          >
            <template #option="slotProps">
              <b-badge :variant="getYesNoStateColor(slotProps.option)">
                {{ $t(slotProps.option) }}
              </b-badge>
            </template>
          </Dropdown>
        </template>
      </Column>

      <Column
        v-if="false"
        field="netRegistrationSentAt"
        :header="$t('netRegistrationSentAt_short')"
        sortable
        :headerStyle="{ 'max-width': '100px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <span v-if="slotProps.data.netRegistrationSentAt">
            {{ $date(slotProps.data.netRegistrationSentAt) }}
          </span>
        </template>

        <template #filter="{filterModel,filterCallback}">
          <Calendar
            v-model="filterModel.value"
            @input="filterCallback()"
            selectionMode="single"
            dateFormat="dd.mm.yy"
            placeholder=">="
          >
          </Calendar>
        </template>
      </Column>

      <Column
        v-if="false"
        field="operationRegistrationSentAt"
        :header="$t('operationRegistrationSentAt_short')"
        sortable
        :headerStyle="{ 'max-width': '100px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <span v-if="slotProps.data.operationRegistrationSentAt">
            {{ $date(slotProps.data.operationRegistrationSentAt) }}
          </span>
        </template>
        <template #filter="{filterModel,filterCallback}">
          <Calendar
            v-model="filterModel.value"
            @input="filterCallback()"
            selectionMode="single"
            dateFormat="dd.mm.yy"
            placeholder=">="
          >
          </Calendar>
        </template>
      </Column>

      <Column
        v-if="isAdmin"
        field="installation.electricMeterChangedAt"
        filterField="electricMeterChangedAt"
        :header="$t('electricMeterChangedAt_short')"
        sortable
        :headerStyle="{ 'max-width': '100px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <span
            v-if="slotProps.data.installation && slotProps.data.installation.electricMeterChangedAt"
          >
            {{ $date(slotProps.data.installation.electricMeterChangedAt) }}
          </span>
        </template>
        <template #filter="{filterModel,filterCallback}">
          <Calendar
            v-model="filterModel.value"
            @input="filterCallback()"
            selectionMode="single"
            dateFormat="dd.mm.yy"
            placeholder=">="
          >
          </Calendar>
        </template>
      </Column>

      <Column
        v-if="isAdmin && isColumnSelected('employerName')"
        field="employer.name"
        :header="$t('EMPLOYER_SHORT')"
        filterField="employerName"
        sortable
        :showFilterMenu="false"
      >
      </Column>

      <Column
        v-if="isAdmin && isColumnSelected('clientName')"
        field="client.name"
        :header="$t('client')"
        sortable
      >
      </Column>

      <Column
        field="newestMeasurementAppointment.installationStartAt"
        :header="$t('measurementAppointment')"
        sortable
        :headerStyle="{ 'max-width': '150px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <span
            v-if="
              slotProps.data.newestMeasurementAppointment &&
                slotProps.data.newestMeasurementAppointment.installationStartAt
            "
          >
            {{ $date(slotProps.data.newestMeasurementAppointment.installationStartAt) }}
          </span>
        </template>
      </Column>

      <Column
        field="newestInstallationAppointment.installationStartAt"
        :header="$t('installationAt')"
        sortable
        :headerStyle="{ 'max-width': '150px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <span
            v-if="
              slotProps.data.newestInstallationAppointment &&
                slotProps.data.newestInstallationAppointment.installationStartAt
            "
          >
            {{ $date(slotProps.data.newestInstallationAppointment.installationStartAt) }}
          </span>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Breadcrumbs from '@/components/Breadcrumbs';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import { FilterMatchMode } from 'primevue/api';
import {
  getYesNoStateColor,
  getProjectStateColor,
  yesNoStateReversedColor,
  projectTypeStateColor,
} from '@/helpers/colors';
import { yesNo, projectState } from '@/helpers/enums';
import MultiSelect from 'primevue/multiselect';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import ButtonWait from '@/components/ButtonWait.vue';
import InputSwitch from 'primevue/inputswitch';
import { generateGoogleMapsLink, startTeamsCall } from '@/helpers/integration';

export default {
  components: {
    DataTable,
    Breadcrumbs,
    Column,
    InputText,
    MultiSelect,
    Dropdown,
    Calendar,
    ButtonWait,
    InputSwitch,
  },
  data() {
    return {
      isMapVisible: false,
      selectedCity: null,
      archiveMode: false,
      loading: false,
      selectedColumns: [],
      columns: [
        { field: 'employerName', header: this.$t('EMPLOYER_SHORT') },
        { field: 'clientName', header: this.$t('client') },
        { field: 'projectState', header: this.$t('projectState') },
      ],
      defaultSortField: 'number',
      defaultSortOrder: -1,
      defaultFilters: {
        number: { value: null, matchMode: FilterMatchMode.EQUALS },
        clientProjectNumber: { value: null, matchMode: FilterMatchMode.EQUALS },
        employerProjectNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
        customerLastname: { value: null, matchMode: FilterMatchMode.CONTAINS },
        customerFirstname: { value: null, matchMode: FilterMatchMode.CONTAINS },
        customerZip: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        customerCity: { value: null, matchMode: FilterMatchMode.CONTAINS },
        customerPhone: { value: null, matchMode: FilterMatchMode.CONTAINS },
        customerMobile: { value: null, matchMode: FilterMatchMode.CONTAINS },
        employerName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        powerCompany: { value: null, matchMode: FilterMatchMode.CONTAINS },
        initialInformationSent: { value: null, label: null, matchMode: FilterMatchMode.EQUALS },
        deliveryAt: { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
        installationAt: { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
        createdAt: { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
        consumablesDeliveredAt: {
          value: null,
          matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
        },
        netRegistrationSentAt: { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
        operationRegistrationSentAt: {
          value: null,
          matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
        },
        electricMeterChangedAt: {
          value: null,
          matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
        },
        netRegistrationRequirementsFullfilledState: {
          value: null,
          label: null,
          matchMode: FilterMatchMode.EQUALS,
        },
        operationRegistrationRequirementsFullfilledState: {
          value: null,
          label: null,
          matchMode: FilterMatchMode.EQUALS,
        },
        consumablesInStockState: {
          value: null,
          label: null,
          matchMode: FilterMatchMode.EQUALS,
        },
        netRegistrationDocumentsApprovedState: {
          value: null,
          label: null,
          matchMode: FilterMatchMode.EQUALS,
        },
        operationRegistrationDocumentsApprovedState: {
          value: null,
          label: null,
          matchMode: FilterMatchMode.EQUALS,
        },
        solarPlantOverallPower: {
          value: null,
          matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
        },
        solarPanelCount: {
          value: null,
          matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
        },
        deferredState: { value: null, label: null, matchMode: FilterMatchMode.EQUALS },
      },
      defaultCustomFilters: {
        employers: [],
        clients: [],
        projectStates: [],
        projectTypeStates: [],
        askEmployerCategoryStates: [],
        installationTeams: [],
        installationTypes: [],
        originalInstallationTypes: [],
        electricMeterChanged: null,
        archiveMode: { value: 'ACTIVE' },
      },
      tableState: {
        pagination: {
          page: 0,
          rowsPerPage: 10,
        },
        sortField: this.defaultSortField,
        sortOrder: this.defaultSortOrder,
        filterName: 'projects-paginated-table-filters',
        filters: this.defaultFilters,
        customFilters: this.defaultCustomFilters,
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'getCurrentUser', 'isClient', 'isGuest']),
    ...mapGetters([
      'isLoading',
      'getProjects',
      'getProjectCount',
      'getEmployers',
      'getClients',
      'getEnumValues',
    ]),
    breadcrumbs() {
      return [{ name: 'Home', route: { name: 'home' } }, { name: this.$t('projects') }];
    },
    projects() {
      // console.log('projects()');
      // this.getProjects.map((project) => console.log(project.number, project.customer.lastname));
      return this.getProjects;
    },
    pageOffset() {
      return this.tableState.pagination.page * this.tableState.pagination.rowsPerPage;
    },
    employers() {
      if (this.isAdmin) {
        return this.getEmployers.map((employer) => ({
          value: employer.id.toString(),
          label: employer.name,
        }));
      } else if (this.tableState.customFilters.client) {
        return this.getEmployers
          .filter((employer) => employer.client.id === this.tableState.customFilters.client.value)
          .map((employer) => ({ value: employer.id.toString(), label: employer.name }));
      } else {
        return [];
      }
    },

    clients() {
      if (this.isAdmin) {
        return this.getClients.map((client) => ({
          value: client.id.toString(),
          label: client.name,
        }));
      } else if (this.isClient) {
        return this.getCurrentUser.clients.map((client) => ({
          value: client.id.toString(),
          label: client.name,
        }));
      } else {
        return [];
      }
    },

    projectStates() {
      const projectStates = this.getEnumValues('ProjectState').map((projectState) => {
        return { value: projectState, label: this.$t(projectState) };
      });
      return projectStates;
    },

    projectTypeStates() {
      const projectTypeStates = this.getEnumValues('ProjectTypeState').map((projectTypeState) => {
        return { value: projectTypeState, label: this.$t(projectTypeState) };
      });
      return projectTypeStates;
    },

    askEmployerCategoryStates() {
      const askEmployerCategoryStates = this.getEnumValues('AskEmployerCategoryState').map(
        (askEmployerCategoryState) => {
          return { value: askEmployerCategoryState, label: this.$t(askEmployerCategoryState) };
        }
      );
      return askEmployerCategoryStates;
    },

    installationTeams() {
      const installationTeams = this.getEnumValues('InstallationTeam').map((installationTeam) => {
        return { value: installationTeam, label: this.$t(installationTeam) };
      });
      return installationTeams;
    },
    installationTypes() {
      const installationTypes = this.getEnumValues('InstallationType').map((installationType) => {
        return { value: installationType, label: this.$t(installationType) };
      });
      return installationTypes;
    },
    originalInstallationTypes() {
      const originalInstallationTypes = this.getEnumValues('OriginalInstallationType').map(
        (originalInstallationType) => {
          return { value: originalInstallationType, label: this.$t(originalInstallationType) };
        }
      );
      return originalInstallationTypes;
    },
    optionsYesNo() {
      return yesNo().map((option) => {
        return { value: option, label: this.$t(option) };
      });
    },

    /**
     * Disable employer dropdown if no client is selected
     */
    employerDropdownDisabled() {
      return !this.isAdmin && this.tableState.customFilters.client ? true : false;
    },
  },
  methods: {
    ...mapActions([
      'fetchProjectsPaginated',
      'fetchProjectsPaginatedByClient',
      'fetchEmployers',
      'fetchClientsPaginated',
      'fetchEmployersByClient',
      'fetchEnumValues',
      'fetchUserByNumber',
      'fetchUserAttachmentUrl',
    ]),
    ...mapActions('auth', ['buildUserProjectExport', 'refreshUser', 'buildUserProjectOfferExport']),
    /**
     * Start export build on server
     * Is provided to ButtonWait as callback function
     */
    showMap(city) {
      this.selectedCity = city;
      this.isMapVisible = !this.isMapVisible;
    },
    async startExportBuild() {
      if (this.getCurrentUser) {
        await this.buildUserProjectExport(this.getCurrentUser.id);
      }
    },
    /**
     * Start export build on server
     * Is provided to ButtonWait as callback function
     */
    async startOfferExportBuild() {
      if (this.getCurrentUser) {
        await this.buildUserProjectOfferExport(this.getCurrentUser.id);
      }
    },
    /**
     * Fetch build state from server (get's called once per second)
     * Is provided to ButtonWait as callback function
     */
    async fetchExportBuildState() {
      await this.refreshUser();

      if (this.getCurrentUser.projectExportBuildState === 'FINISHED') {
        this.downloadProjectExport('projectExportAttachment');
      }
      return this.getCurrentUser.projectExportBuildState;
    },

    /**
     * Fetch build state from server (get's called once per second)
     * Is provided to ButtonWait as callback function
     */
    async fetchOfferExportBuildState() {
      await this.refreshUser();
      if (this.getCurrentUser.projectOfferExportBuildState === 'FINISHED') {
        this.downloadProjectExport('projectOfferExportAttachment');
      }
      return this.getCurrentUser.projectOfferExportBuildState;
    },

    /**
     * Download a finished export file
     * @param attachmentType
     */
    async downloadProjectExport(attachmentType) {
      const url = await this.fetchUserAttachmentUrl({
        userId: this.getCurrentUser.id,
        attachmentType: attachmentType,
      });

      window.open(url);
    },

    /**
     * Load remote table data
     */
    async loadTableData() {
      // console.log('loadTableData()', this.tableState.customFilters.clients);

      if (this.isClient) {
        await this.fetchProjectsPaginatedByClient({
          clientId: this.getCurrentUser.client.id,
          page: this.pageOffset,
          pageSize: this.tableState.pagination.rowsPerPage,
          sortField: this.tableState.sortField,
          sortOrder: this.tableState.sortOrder,
          filters: { ...this.tableState.filters, ...this.tableState.customFilters },
        });
      }
      if (this.isGuest) {
        await this.fetchProjectsPaginatedByClient({
          clientId: this.getCurrentUser.client.id,
          page: this.pageOffset,
          pageSize: this.tableState.pagination.rowsPerPage,
          sortField: this.tableState.sortField,
          sortOrder: this.tableState.sortOrder,
          filters: { ...this.tableState.filters, ...this.tableState.customFilters },
        });
      } else if (this.isAdmin) {
        await this.fetchProjectsPaginated({
          page: this.pageOffset,
          pageSize: this.tableState.pagination.rowsPerPage,
          sortField: this.tableState.sortField,
          sortOrder: this.tableState.sortOrder,
          filters: { ...this.tableState.filters, ...this.tableState.customFilters },
        });
      }
    },
    /**
     * Load more results from server or cache on pagination click
     */
    async onPage(event) {
      this.tableState.pagination.page = event.page;
      this.tableState.pagination.rowsPerPage = event.rows;
      // console.log('onPage', event);
      this.loadTableData();
    },
    /**
     * Load results from server/cache on sort
     */
    async onSort(event) {
      this.tableState.sortField = event.sortField;
      this.tableState.sortOrder = event.sortOrder;
      this.loadTableData();
    },
    /**
     * Load results from server/cache on filter
     */
    async onFilter(event) {
      this.loadTableData();
    },
    async onRemoveFilters(event) {
      // console.log('onRemoveFilters', event);
      // console.log('onRemoveFilters', this.$refs.projectsPaginatedTable);
      this.tableState.sortOrder = this.defaultSortOrder;
      this.tableState.sortField = this.defaultSortField;
      this.tableState.filters = this.defaultFilters;
      this.tableState.pagination.page = 0;
      this.tableState.pagination.rowsPerPage = 20;
      this.tableState.customFilters = this.defaultCustomFilters;
      this.tableState.customFilters.employers = [];
      this.tableState.customFilters.clients = [];
      this.tableState.customFilters.projectStates = [];
      this.archiveMode = false;
      localStorage.removeItem('projects-paginated-custom-table-filters');
      await this.loadTableData();
    },
    async onStateRestore(event) {
      // console.log('onStateRestore', event);
      this.setupFilters();

      if (this.tableState) {
        this.tableState.sortField = event.sortField ? event.sortField : this.defaultSortField;
        this.tableState.sortOrder = event.sortOrder ? event.sortOrder : this.defaultSortOrder;
        this.tableState.pagination.page = 0; // event.first / event.rows;
        this.tableState.pagination.rowsPerPage = event.rows;
        this.tableState.pagination.rowsPerPage = event.rows;
      }
      const customFiltersFromStorage = JSON.parse(
        localStorage.getItem('projects-paginated-custom-table-filters')
      );
      this.tableState.customFilters = customFiltersFromStorage
        ? customFiltersFromStorage
        : this.defaultCustomFilters;

      this.archiveMode =
        this.tableState.customFilters.archiveMode.value === 'ARCHIVED' ? true : false;
    },
    onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col));
    },

    async onChangeArchiveMode(event) {
      this.tableState.customFilters.archiveMode.value = this.archiveMode ? 'ARCHIVED' : 'ACTIVE';
      this.tableState.customFilters.clients = [];
      localStorage.setItem(
        'projects-paginated-custom-table-filters',
        JSON.stringify(this.tableState.customFilters)
      );
      await this.fetchClientsPaginated({
        pagination: { pageSize: 100, skip: 0 },
        sorting: { sortOrder: 1, sortField: 'name' },
        filters: { archiveState: { value: this.archiveMode ? 'ARCHIVED' : 'ACTIVE' } },
      });
      await this.loadTableData();
    },

    /**
     *
     */
    async onEmployerFilter(value) {
      this.tableState.customFilters.employers = this.employers.filter((employer) =>
        value.includes(employer)
      );
      localStorage.setItem(
        'projects-paginated-custom-table-filters',
        JSON.stringify(this.tableState.customFilters)
      );
      await this.loadTableData();
    },

    /**
     *
     */
    async onClientFilter(value) {
      this.tableState.customFilters.clients = this.clients.filter((client) =>
        value.includes(client)
      );
      localStorage.setItem(
        'projects-paginated-custom-table-filters',
        JSON.stringify(this.tableState.customFilters)
      );
      await this.loadTableData();
    },
    /**
     *
     */
    async onProjectStateFilter(value) {
      this.tableState.customFilters.projectStates = this.projectStates.filter((projectState) =>
        value.includes(projectState)
      );
      localStorage.setItem(
        'projects-paginated-custom-table-filters',
        JSON.stringify(this.tableState.customFilters)
      );
      await this.loadTableData();
    },
    /**
     *
     */
    async onProjectTypeStateFilter(value) {
      this.tableState.customFilters.projectTypeStates = this.projectTypeStates.filter(
        (projectTypeState) => value.includes(projectTypeState)
      );
      localStorage.setItem(
        'projects-paginated-custom-table-filters',
        JSON.stringify(this.tableState.customFilters)
      );
      await this.loadTableData();
    },
    /**
     *
     */
    async onAskEmployerCategoryStateFilter(value) {
      this.tableState.customFilters.askEmployerCategoryStates = this.askEmployerCategoryStates.filter(
        (askEmployerCategoryState) => value.includes(askEmployerCategoryState)
      );
      localStorage.setItem(
        'projects-paginated-custom-table-filters',
        JSON.stringify(this.tableState.customFilters)
      );
      await this.loadTableData();
    },

    /**
     *
     */
    async onInstallationTeamFilter(value) {
      this.tableState.customFilters.installationTeams = this.installationTeams.filter(
        (installationTeam) => value.includes(installationTeam)
      );
      localStorage.setItem(
        'projects-paginated-custom-table-filters',
        JSON.stringify(this.tableState.customFilters)
      );
      await this.loadTableData();
    },

    /**
     *
     */
    async onInstallationTypeFilter(value) {
      this.tableState.customFilters.installationTypes = this.installationTypes.filter(
        (installationType) => value.includes(installationType)
      );
      localStorage.setItem(
        'projects-paginated-custom-table-filters',
        JSON.stringify(this.tableState.customFilters)
      );
      await this.loadTableData();
    },

    /**
     *
     */
    async onOriginalInstallationTypeFilter(value) {
      this.tableState.customFilters.originalInstallationTypes = this.originalInstallationTypes.filter(
        (originalInstallationType) => value.includes(originalInstallationType)
      );
      localStorage.setItem(
        'projects-paginated-custom-table-filters',
        JSON.stringify(this.tableState.customFilters)
      );
      await this.loadTableData();
    },

    /**
     *
     */
    async onElectricMeterChangedFilter(value) {
      // console.log('onElectricMeterChangedFilter', value);
      this.tableState.customFilters.electricMeterChanged = value;
      localStorage.setItem(
        'projects-paginated-custom-table-filters',
        JSON.stringify(this.tableState.customFilters)
      );
      await this.loadTableData();
    },

    /**
     *
     */
    isColumnSelected(column) {
      // console.log('isColumnSelected', this.selectedColumns);
      return this.selectedColumns.find((col) => column === col.field);
    },

    setupFilters() {
      // console.log('setupFilters', this.tableState);

      this.tableState.filters = this.tableState.filters
        ? this.tableState.filters
        : this.defaultFilters;
      this.tableState.pagination.page = 0;
      this.tableState.pagination.rowsPerPage = 15;
      this.tableState.customFilters = this.tableState.customFilters
        ? this.tableState.customFilters
        : this.defaultCustomFilters;
      this.tableState.sortField = this.tableState.sortField
        ? this.tableState.sortField
        : this.defaultSortField;

      this.tableState.sortOrder = this.tableState.sortOrder
        ? this.tableState.sortOrder
        : this.defaultSortOrder;
    },

    getProjectStateColor,
    getYesNoStateColor,
    yesNo,
    yesNoStateReversedColor,
    projectTypeStateColor,
    generateGoogleMapsLink,
  },
  async created() {
    this.setupFilters();
  },
  async mounted() {
    this.selectedColumns = this.columns;
    await this.fetchEnumValues('ProjectState');
    await this.fetchEnumValues('ProjectTypeState');
    await this.fetchEnumValues('AskEmployerCategoryState');
    await this.fetchEnumValues('InstallationTeam');
    await this.fetchEnumValues('InstallationType');
    await this.fetchEnumValues('OriginalInstallationType');

    if (this.isClient) {
      await this.fetchEmployersByClient(this.getCurrentUser.client.id);
      this.tableState.customFilters.client = {
        value: this.getCurrentUser.client.id.toString(),
        label: this.getCurrentUser.client.name,
      };
    } else if (this.isAdmin) {
      await this.fetchEmployers();
      await this.fetchClientsPaginated({
        pagination: { pageSize: 100, skip: 0 },
        sorting: { sortOrder: 1, sortField: 'name' },
        filters: { archiveState: { value: this.archiveMode ? 'ARCHIVED' : 'ACTIVE' } },
      });
    }
    this.loading = false;
    await this.loadTableData();
  },
};
</script>

<style lang="scss">
.p-column-filter-menu-button {
  display: none;
}
.p-column-filter-clear-button {
  display: none;
}
.table-header {
  display: flex;
  justify-content: space-between;
}
.table-header-left {
  display: flex;
  justify-content: flex-start;
}
.table-header-right {
  display: flex;
  justify-content: flex-end;
  column-gap: 1em;
}

.p-calendar .p-datepicker {
  min-width: 100%;
  width: 29em;
}

/** Fix Dropdown overlay */
.p-datatable-responsive-scroll > .p-datatable-wrapper {
  min-height: 37rem;
}
.location-icon {
  cursor: pointer;
  font-size: 24px;
  color: #007bff;
  background-color: transparent;
  border: none;
  padding: 0;
  margin-left: 13px;
  display: inline-block;
}
</style>
